import React from "react"
import { graphql, Link } from "gatsby"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import ButtonLink from "../components/ButtonLink"
import SectionTitle from "../components/SectionTitle"
import NewsletterSignUp from "../components/NewsletterSignup"
import Hero from "../components/Hero"

// Images
// Need to be refactored to use Gatbsy Image

import BlogCard from "../components/blog/BlogCard"
import Syllabics from "../components/Syllabics"

import DisplayPosts from "../components/DisplayPosts"
import HighlightCard from "../components/index/HighlightCard"

import CareIcon from "../images/svgs/CareIcon"
import MedicineIcon from "../images/svgs/MedicineIcon"
import CommunitiesIcon from "../images/svgs/PeopleIcon"
import { GrInfo } from "react-icons/gr"

const IndexPage = ({ data }) => {
  const blogPosts = data.allSanityBlogPost.edges
  const homePageData = data?.allSanityHomePage?.edges[0]?.node || {}
  const {
    englishTitle = "Error: No Title Provided",
    syllabicsTitle,
    aboutUsSection,
    heroImage,
  } = homePageData

  return (
    <Layout>
      <SEO title="Home" />
      <Hero
        englishTitle={englishTitle}
        syllabics={syllabicsTitle}
        heroImage={heroImage ? heroImage.asset : null}
        bgClassName="bg-bottom"
      >
        <div className="hidden md:flex mb-20 mx-auto mt-3 z-10 ">
          <ButtonLink url="about" text="Learn More" color="secondary" />
          <ButtonLink
            classNames="ml-4"
            url="diabetes"
            text="Diabetes Information"
            color="primary"
          />
        </div>
      </Hero>
      <div className="grid lg:grid-flow-col md:items-center justify-center md:-mt-12 mb-10 mt-10 z-10 ">
        <HighlightCard
          icon={<CommunitiesIcon />}
          className="bg-primary-light hover:bg-primary-dark"
          to="about"
          title="About Us"
        />
        <HighlightCard
          icon={<CareIcon />}
          className="bg-secondary-light hover:bg-secondary-dark"
          to="care-directory"
          title="Care Directory"
        />
        <HighlightCard
          icon={<MedicineIcon />}
          className="bg-tertiary-light hover:bg-tertiary-dark"
          to="nutrition-plant-medicines"
          title="Nutrition & Plant Medicines"
        />
      </div>

      <section className="flex flex-col mb-12 text-primary-dark">
        <SectionTitle>
          <Syllabics className="text-secondary-light">edlaDH Pmafs</Syllabics>
          <br />
          About Us
        </SectionTitle>
        <p className=" text-justify mx-4 md:mx-32 lg:mx-64 mb-8 text-lg font-medium">
          {aboutUsSection}
        </p>
        <ButtonLink
          url="about"
          text="Learn More"
          color="primary"
          classNames="m-4 mx-auto text-xl"
        />
      </section>

      {/* <section className="grid mb-10">
        <SectionTitle className="">
          {" "}
          <Syllabics className="text-secondary-light">
            jor sqq3ladD kqxlaD
          </Syllabics>
          <br />
          What's New
        </SectionTitle>

        <DisplayPosts posts={blogPosts} />

        <ButtonLink
          text="More News"
          color="tertiary"
          classNames="justify-self-center mt-8 text-xl"
          url="/news"
        />
      </section> */}
      {/* <NewsletterSignUp>Get Notified of News & Events</NewsletterSignUp> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityBlogPost(
      filter: { inNewsFeed: { eq: true } }
      limit: 2
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...DisplayPostsFragment
        }
      }
    }

    allSanityHomePage {
      edges {
        node {
          englishTitle
          syllabicsTitle
          aboutUsSection
          heroImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
