import React from "react"

const PeopleIcon = () => {
  return (
    <svg viewBox="0 0 171 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 103C3 89.3793 10.8448 77.6552 22.2241 71.9655C16.4483 67.4828 12.6552 60.4138 12.6552 52.569C12.6552 39.0345 23.6896 28 37.2241 28C43 28 48.3448 29.9828 52.4827 33.3448"
        stroke="white"
        strokeWidth="5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.5517 103C39.5517 84.8966 49.9827 69.2069 65.1551 61.7069C57.3965 55.6724 52.4827 46.3621 52.4827 35.7586C52.4827 17.6552 67.1379 3 85.2413 3C103.345 3 118 17.6552 118 35.7586C118 46.1897 113.172 55.4138 105.586 61.4483C121.103 68.8621 131.793 84.7241 131.793 103"
        stroke="white"
        strokeWidth="5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M117.914 33.5173C122.138 30.069 127.569 28 133.431 28C146.965 28 158 39.0345 158 52.569C158 60.3276 154.379 67.3103 148.69 71.7931C160.328 77.3965 168.345 89.2069 168.345 103"
        stroke="white"
        strokeWidth="5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default PeopleIcon
