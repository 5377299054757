import React from "react"

const MedicineIcon = () => {
  return (
    <svg viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.8217 15.6276C34.2991 6.11 15.6648 5.00637 2.47476 5.00637C1.10874 5.00637 0 6.11487 0 7.4809C0 20.6709 1.09876 39.3053 10.6213 48.828C14.6402 52.847 20.8022 54.6436 26.9097 54.6436C33.5468 54.6436 40.1245 52.5203 43.8217 48.8231C50.9142 41.7306 52.2159 24.0168 43.8217 15.6276ZM40.3226 45.3287C35.0267 50.6195 20.3222 51.5253 14.1257 45.3287C6.43921 37.6425 5.10775 22.0272 4.96414 9.97051C17.0208 10.1141 32.6362 11.4453 40.3224 19.1318C46.5191 25.3283 45.6134 40.0379 40.3226 45.3287Z"
        fill="white"
      />
      <path
        d="M106.525 14.7961C93.3353 14.7961 74.7009 15.8949 65.1781 25.4174C56.7841 33.8066 58.0856 51.5204 65.1781 58.6129C68.8753 62.3052 75.4481 64.4285 82.0853 64.4285C88.1927 64.4285 94.3498 62.6368 98.3785 58.618C107.896 49.0955 109 30.4611 109 17.2709C109 15.9049 107.891 14.7961 106.525 14.7961ZM94.8695 55.1137C88.6729 61.3102 73.9633 60.4045 68.6725 55.1137C63.3817 49.8229 62.476 35.1132 68.6725 28.9167C76.359 21.2302 91.9741 19.899 104.031 19.7554C103.887 31.817 102.556 47.4323 94.8695 55.1137Z"
        fill="white"
      />
      <path
        d="M88.6532 36.2171C88.0692 34.9747 86.3963 34.5492 85.1639 35.1232C66.1831 44.0469 56.8585 59.6078 52.6217 75.604C47.3803 41.5919 32.2204 31.223 25.7912 26.8231C24.9894 26.2737 24.3657 25.8728 24.0194 25.5313C23.0542 24.5662 21.4852 24.5662 20.5201 25.5313C19.555 26.4965 19.555 28.0655 20.5201 29.0306C21.0101 29.5157 21.8564 30.1245 22.9997 30.9063C30.2456 35.8607 49.4936 49.031 49.4936 101.519C49.4936 102.885 50.6024 103.994 51.9684 103.994C53.3344 103.994 54.4431 102.885 54.4431 101.524C54.4431 80.3162 60.1399 52.3572 87.2723 39.6076C87.2723 39.6027 87.6732 39.4145 87.6732 39.4145C88.9056 38.8304 89.2371 37.4544 88.6532 36.2171Z"
        fill="white"
      />
    </svg>
  )
}

export default MedicineIcon
