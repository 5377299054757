import { Link } from "gatsby"
import React from "react"
import CareIcon from "../../images/svgs/CareIcon"

import Syllabics from "../Syllabics"

const HighlightCard = ({
  icon = <CareIcon />,
  className = "",
  to = "about-us",
  title = "About Us",
}) => {
  return (
    <Link
      to={`/${to}`}
      className={`grid grid-rows-highightCard justify-items-center justify-center mx-5 mb-5 h-64 w-64 bg-primary-light hover:bg-primary-dark rounded-full p-10 shadow-md ${className}`}
    >
      <div className="grid items-center h-32  ">
        <div className=" w-32 ">{icon}</div>
      </div>

      <h3
        className="text-white font-heading text-center  pb-0 mt-2"
        style={{ fontSize: "1.3rem" }}
      >
        {title}
      </h3>
    </Link>
  )
}

export default HighlightCard
